<!-- Desktop -->

	<div class="footer hidden-xs"style="background-color: transparent;">
        <div class="container">
           <div class="row">
            <div class="col-md-12">
              <p style="color: black;">© Copyright 2022 Hong Seng Gloves Sdn. Bhd. 
                Registration No. 202001022653 (1378973-A). All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>

<!--Mobile Phones-->

<div class="footer mobile-w hidden-sm hidden-md hidden-lg"style="background-color: transparent;">
  <div class="container">
     <div class="row">
      <div class="col-md-12">
        <p style="color: black;">© Copyright 2022 Hong Seng Gloves Sdn. Bhd. 
          Registration No. 202001022653 (1378973-A). All Rights Reserved.</p>
      </div>
    </div>
  </div>
</div>

