// Core Module
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Directive } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

// External Module 
import { NgxSpinnerModule } from "ngx-spinner";
import { SlickCarouselModule } from 'ngx-slick-carousel';

// Component 
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ContactComponent } from './contact/contact.component';
import { EsgComplianceComponent } from './esg_compliance/esg_compliance.component';
import { NewsComponent } from './news/news.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { OurTeamComponent } from './our-team/our-team.component';
import { TimelineComponent } from './timeline/timeline.component';
import { ProductComponent } from './product/product.component';
import { ProductSpecComponent } from './product_spec/product_spec.component';
import { CareerComponent } from './career/career.component';
// import { AppearDirectiveDirective } from './appear-directive.directive';

// Animation 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ContactComponent,
    AboutUsComponent,
    // AppearDirectiveDirective
    EsgComplianceComponent,
    NewsComponent,
    TimelineComponent,
    OurTeamComponent,
    ProductComponent,
    ProductSpecComponent,
    CareerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxSpinnerModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    // AppearDirectiveDirective
    // RouterModule.forRoot(routes, { anchorScrolling: 'enabled'})
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [Title],
  bootstrap: [AppComponent]
})

export class AppModule { }

