<app-header></app-header>

<!--Laptop-->
<div class="section hidden-sm hidden-xs" id="section">
     <div class="background-image background-image-fixed hidden-sm hidden-xs" style="background-image : url('./assets/image/Desktop/PAGE_1_BACKGROUND_01.jpg')"></div>
  
    <div class="container div-center">
      <div class="row" style="color: white;">
        <div class="col-md-7 col-xs-6" >
      
        <h5 class="title">HONG SENG GLOVES SDN BHD</h5> 
        
        <h3> 
          Hong Seng Gloves supports OBM and private label OEM manufacturing as competitive
          as other leading gloves manufacturers.
        </h3>
      <br/>
      <br/>
      <h4 class="tagline"><i>Reliable . Quality . Defined</i></h4>
        </div> 
    </div>
</div>
</div>


<!--Mobile-->
<div class="section hidden-lg hidden-md" id="section" >
  <div class="background-image background-image-fixed hidden-lg hidden-md" style="background-image : url('./assets/image/Strip(MobileResponsive)/M_PAGE_1_BACKGROUND.jpg')">

 <div class="container div-center">
   <div class="row" style="color: white;">
     <div class="col-md-7 col-xs-6" >
   
     <p class="title">HONG SENG GLOVES SDN BHD</p> 
     
     <h3> 
       Hong Seng Gloves supports OBM and private label OEM manufacturing as competitive
       as other leading gloves manufacturers.
     </h3>
     <br/>
     <br/>
     <h4 class="tagline"><i>Reliable . Quality . Defined</i></h4>
     </div> 
 </div>
</div>
</div>
</div>

<div class="section">
<div id="aboutUs"></div> 
  <div class="container">
 
    <div class="col-md-8 col-xs-8">
    <h4 style="font-weight: bold;"> About Us</h4> <br>
    <p>
      Hong Seng Gloves Sdn. Bhd. (HS Gloves) is a wholly-owned subsidiary of public listed company, Hong Seng Consolidated 
      Berhad strategically located in Sungai Petani, Kedah Malaysia, with one hour drive distance from Penang International 
      Airport. Hong Seng Gloves Sdn. Bhd. is supported by our partner Howellcare Industries Sdn. Bhd. as our turnkey partner 
      and business Sales and Marketing arm. &nbsp; </p>
    </div>

    <div class="col-md-4 col-xs-4">
      <img src="./assets/image/Icons/HSLOGO.png" class="center-block img-responsive" >
    </div> 

    <div class="col-md-12 col-xs-12">
     <br> <p>
      This 10-acres of land consists of Phase 1 and Phase 2 double former manufacturing plant, equipped with leading- 
      edge technology that manufactures high quality powder free nitrile gloves for various glove applications. Our first 2 
      production lines in Phase 1 commenced in October 2021. At present time, HS Gloves phase 1 produces up to 2 billion pieces 
      of glove annually and target to expand to 4 billion pieces of gloves with our phase 2 completion. 
    </p>
        </div>

      <div class="col-md-12 col-xs-12">
       <br><p>
        Hong Seng Gloves supports OBM and private label OEM manufacturing. Despite being the new player in the industry, 
        Hong Seng Gloves is as competitive as other leading glove manufacturers. Our enthusiastic team of skilled and dedicated 
        experts who endlessly strive to deliver premium quality of gloves tailored to customer’s requirements and needs.
      </p>
            </div>

  </div>
</div> 
    
<app-footer></app-footer>

