
  <div class="navbar navbar-default navbar-fixed-top hidden-xs">
    <div class="container">
      <div class="navbar-header">
      
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-ex-collapse">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
         </button>

        <a  class="navbar-brand" style="cursor: pointer;" (click)="goToHome()"><img height="100%" alt="Brand" 
          src="./assets/image/Icons/HSLOGO.png" class="center-block" id="logo"></a>
      
        </div>
      
      <div class="collapse navbar-collapse" id="navbar-ex-collapse">
        <ul class="nav navbar-nav navbar-right">

            
    <div class="dropdown">
      <button class="dropbtn"><span class="effect" (click)="goToHome()">Home</span></button>
      <div class="dropdown-content">
       <li style="cursor: pointer;"> 
        <a (click)="goToFragment('aboutUS')">About Us</a>
      </li>
      <li style="cursor: pointer;"> 
        <!-- <a (click)="goToTimeline()">Our Timeline, Manufacturing Plant Progress</a> -->
        <a (click)="goToTimeline()">Our Timeline</a>
      </li>
      <li style="cursor: pointer;">
        <a (click)="goToOurTeam()">Our Team</a>
      </li>
      </div>
    </div>

       <div class="dropdown">
      <button class="dropbtn"><span class="effect"> Products</span></button>
      <div class="dropdown-content">
       <li style="cursor: pointer;"> 
        <a (click)="goToProduct()">Quality Policy, Stringent Quality Control, Product Compliances</a>
      </li>
      <li style="cursor: pointer;"> 
      <a (click)="goToProductSpec()">Product Specification</a>
      </li>
  
      </div>
    </div>
    <div class="dropdown">
      <button class="dropbtn"><span class="effect" (click)="goToEsgCompliance()">ESG Compliance</span></button>
    </div>
    <!-- <li style="cursor: pointer;">
      <a class="dropbtn" (click)="goToEsgCompliance()"><span class="effect">ESG Compliance</span></a>
    </li> -->

    <!-- <li style="cursor: pointer;">
      <a (click)="goToNews()"><span class="effect">News</span></a>
    </li> -->
    <div class="dropdown">
      <button class="dropbtn"><span class="effect" (click)="goToNews()">News</span></button>
    </div>

    <div class="dropdown">
      <button class="dropbtn"><span class="effect" (click)="goToCareer()">Career</span></button>
    </div>

    <!-- <li style="cursor: pointer;">
      <a (click)="goToContact()"><span class="effect">Contact Us</span></a>
    </li> -->
    <div class="dropdown">
      <button class="dropbtn"><span class="effect" (click)="goToContact()">Contact Us</span></button>
    </div>

    <!-- <li style="cursor: pointer;">
      <a (click)="goToContact()"><span class="effect">Career</span></a>
    </li> -->
    </ul>

      </div>
    </div>
  </div>

  
  <div class="navbar navbar-default navbar-fixed-top hidden-sm hidden-md hidden-lg">
    <div class="container">
      <div class="navbar-header">
      
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-ex-collapse">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
         </button>

        <a  class="navbar-brand" style="cursor: pointer;" (click)="goToHome()"><img height="100%" alt="Brand" 
          src="./assets/image/Icons/HSLOGO.png" class="center-block" id="logo"></a>
      
        </div>
      
      <div class="collapse navbar-collapse" id="navbar-ex-collapse">
        <ul class="nav navbar-nav navbar-right">

            
    <div class="dropdown">
      <button class="dropbtn" data-bs-toggle="dropdown"><span class="effect">Home</span></button>
      <div class="dropdown-menu">
       <li style="cursor: pointer;"> 
        <a (click)="goToAboutUS()">About Us</a>
      </li>  
      <li style="cursor: pointer;"> 
        <!-- <a (click)="goToTimeline()">Our Timeline, Manufacturing Plant Progress</a> -->
        <a (click)="goToTimeline()">Our Timeline</a>
      </li>
      <li style="cursor: pointer;">
        <a (click)="goToOurTeam()">Our Team</a>
      </li>
      </div>
    </div>

       <div class="dropdown">
      <button class="dropbtn" data-bs-toggle="dropdown"><span class="effect"> Products</span></button>
      <div class="dropdown-menu">
       <li style="cursor: pointer;"> 
        <a (click)="goToProduct()">Quality Policy, Stringent Quality Control, <br>Product Compliances</a>
      </li>
      <li style="cursor: pointer;"> 
      <a (click)="goToProductSpec()">Product Specification</a>
      </li>
  
      </div>
    </div>
    <div class="dropdown">
      <button class="dropbtn"><span class="effect" (click)="goToEsgCompliance()">ESG Compliance</span></button>
    </div>
    <!-- <li style="cursor: pointer;">
      <a class="dropbtn" (click)="goToEsgCompliance()"><span class="effect">ESG Compliance</span></a>
    </li> -->

    <!-- <li style="cursor: pointer;">
      <a (click)="goToNews()"><span class="effect">News</span></a>
    </li> -->
    <div class="dropdown">
      <button class="dropbtn"><span class="effect" (click)="goToNews()">News</span></button>
    </div>

    <div class="dropdown">
      <button class="dropbtn"><span class="effect" (click)="goToCareer()">Career</span></button>
    </div>

    <!-- <li style="cursor: pointer;">
      <a (click)="goToContact()"><span class="effect">Contact Us</span></a>
    </li> -->
    <div class="dropdown">
      <button class="dropbtn"><span class="effect" (click)="goToContact()">Contact Us</span></button>
    </div>
    </ul>

      </div>     
    </div>
  </div>    
