<app-header></app-header>

<script type="text/javascript" src="contact.component.js"></script>

<div class="section">
  <div class="layer col-xs-9 col-lg-8">
    <div class="row">
      <h3>Contact Us</h3>
      <!-- <div class="col-md-12 col-lg-7">
        <div class="show col-md-12">
          <p>We want to serve you better and are committed to
            continuously improving our client service standards.</p>
          <p>Let us know what you think.</p>
          <button class="btn btn-default btn-lg track-btn"><a target="_blank" href="https://docs.google.com/forms/d/12gE7UDmxqex--6s0hqSabN0lIireCtdtrXk7sep8hu8/viewform?ts=6232a980&edit_requested=true">Tell Us</a></button>
        </div>
      </div> -->

      <!-- HIDDEN -->
      <div class="col-md-7" style="padding: 0 30;">
        <div class="row g-0" style="margin:0px;">
          <select id="category" class="form-control input-lg" style="padding: 0 15px;"
          #mySelect
          (change)='selectedOpt(mySelect.value)'>
              <option value="default" selected disabled hidde>Select a topic</option>
              <option value="enquiry">Enquiry Form</option>
              <option value="feedback">Customer Feedback Form</option>
          </select>
        </div>

        <div class="row form-pad default g-0" *ngIf="isOpt == 'default'">
          <form class="form-horizontal" role="form">
            <div class="form-group">
            </div>
          </form>
        </div>

        <div class="row form-pad enquiry g-0" *ngIf="isOpt == 'enquiry'">
          <form method="POST" (submit)="sendMail()" class="form-horizontal" role="form" ngNativeValidate>
            <div class="form-group">
              <div class="col-sm-6 mobile-pad">
                <input type="text" class="form-control input-lg" name="enquiryName" [(ngModel)]="enquiryName" [(value)]="enquiryName" id="enquiryName" placeholder="Name" required>
              </div>
              <div class="col-sm-6 mobile-pad">
                <input type="email" class="form-control input-lg" name="enquiryEmail" [(ngModel)]="enquiryEmail" [(value)]="enquiryEmail" id="enquiryEmail" placeholder="Email" required>
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-6 mobile-pad">
                <input type="text" class="form-control input-lg" name="enquiryPhone" [(ngModel)]="enquiryPhone" [(value)]="enquiryPhone" id="enquiryPhone" placeholder="Contact Number"required>
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-12 mobile-pad">
                <textarea name="enquiryMessage" [(ngModel)]="enquiryMessage" [(value)]="enquiryMessage" id="enquiryMessage" placeholder="Message" data-form-field="textarea" class="form-control input-lg"></textarea>
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-12">
                <button type="submit" name="submit" class="btn btn-default btn-lg track-btn">Enquire Now</button>
              </div>
            </div>
          </form>
        </div>

        <div class="row form-pad feedback g-0" *ngIf="isOpt == 'feedback'">
          <form method="POST" (submit)="sendMail()" class="form-horizontal" role="form" ngNativeValidate>
            <div class="form-group">
              <div class="col-sm-6 mobile-pad">
                <input type="text" class="form-control input-lg" name="feedbackName" [(ngModel)]="feedbackName" [(value)]="feedbackName" id="feedbackName" placeholder="Customer Name" required>
              </div>
              <div class="col-sm-6 mobile-pad">
                <input type="email" class="form-control input-lg" name="feedbackEmail" [(ngModel)]="feedbackEmail" [(value)]="feedbackEmail" id="feedbackEmail" placeholder="Email" required>
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-6 mobile-pad">
                <input type="text" class="form-control input-lg" name="feedbackOrganization" [(ngModel)]="feedbackOrganization" [(value)]="feedbackOrganization" id="feedbackOrganization" placeholder="Organization" required>
              </div>
              <div class="col-sm-6 mobile-pad">
                <input type="text" class="form-control input-lg" name="feedbackPhone" [(ngModel)]="feedbackPhone" [(value)]="feedbackPhone" id="feedbackPhone" placeholder="Contact Number" required>
              </div>
            </div>

            <div class="form-group">
              <div class="col-sm-6 mobile-pad">
                <input type="text" class="form-control input-lg" name="feedbackInvoice" [(ngModel)]="feedbackInvoice" [(value)]="feedbackInvoice" id="feedbackInvoice" placeholder="Invoice Number" required>
              </div>
              <div class="col-sm-6 mobile-pad">
                <input type="text" class="form-control input-lg" name="feedbackLot" [(ngModel)]="feedbackLot" [(value)]="feedbackLot" id="feedbackLot" placeholder="Lot Number">
              </div>
            </div>

            <div class="form-group ">
              <div class="col-sm-12 mobile-pad">
                <textarea name="feedbackProduct" [(ngModel)]="feedbackProduct" [(value)]="feedbackProduct" id="feedbackProduct" placeholder="Product description (Product type, size, color etc)" data-form-field="textarea" class="form-control input-lg"></textarea>
              </div>
            </div>
            <div class="form-group ">
              <div class="col-sm-12 mobile-pad">
                <textarea name="feedbackDetails" [(ngModel)]="feedbackDetails" [(value)]="feedbackDetails" id="feedbackDetails" placeholder="Feedback details (Affected quantity, defects description)" data-form-field="textarea" class="form-control input-lg"></textarea>
              </div>
            </div>

            <div class="form-group ">
              <div class="col-sm-12 mobile-pad">
                <!--<label class="btn track-btn input-lg display-flex-center " style="width: 100% !important">
                  <input type="file" id="fileToUpload" name="attachment" class="form-control input-lg" value="Uploadfile" class="btn-file-upload">
                  ///<div id="uploadtitle" style="width: 100% !important">Attachment (photos):</div>
                </label>-->
                <input type="file" id="fileToUpload" name="attachment" (change)="changeListener($event)" class="btn-file-upload" value="Attachment (photos):" multiple />
              </div>
            </div>

            <div class="form-group">
              <div class="col-sm-12">
                <button type="submit" name="submit" class="btn btn-default btn-lg track-btn">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="col-md-12 col-lg-5">
        <div class="col-md-12 col-lg-12 sidebar-info">
          <p>HONG SENG GLOVES SDN. BHD.  (1378973-A)</p>
          <p>Lot 97, Jalan 10, Kawasan Perusahaan Bakar Arang, 08000 Sungai Petani, Kedah Darul Aman, Malaysia.</p>
          <p>Tel &nbsp;: 04-455 8322 / 04-421 1555 <br>Fax : 04-421 1555</p>
          <p>Email: <a href="mailto:inquiry@hongsenggloves.com">inquiry@hongsenggloves.com</a></p>
          <p>Direction: &nbsp;&nbsp;&nbsp;<a target="_blank" href="https://www.waze.com/en/live-map/directions/my/kedah/sungai-petani/hong-seng-gloves-sdn.-bhd.?place=ChIJhbE971XXSjARqjNMlmFo6W8">
                            <img class="mapIcon" src="./assets/image/Icons/waze_icon.png"/></a>    
                       <a target="_blank" href="https://goo.gl/maps/2RQxgAKMHpXw8Qzs5">&nbsp;&nbsp;&nbsp;
                            <img class="mapIcon" src="./assets/image/Icons/google_map_icon.png"/></a>   
            </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="map" style="padding:0;margin: 0px;">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d63528.27480023838!2d100.505967!3d5.638036!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x304ad755ef3db185%3A0x6fe96861964c33aa!2sHong%20Seng%20Gloves%20Sdn.%20Bhd.!5e0!3m2!1sen!2smy!4v1655288838704!5m2!1sen!2smy" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  </div>
</div>

<app-footer class="footer"></app-footer>

