import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HeaderComponent } from './header/header.component';
import { ContactComponent } from './contact/contact.component';
import { EsgComplianceComponent } from './esg_compliance/esg_compliance.component';
import { NewsComponent } from './news/news.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { OurTeamComponent } from './our-team/our-team.component';
import { TimelineComponent } from './timeline/timeline.component';
import { ProductComponent } from './product/product.component';
import { ProductSpecComponent } from './product_spec/product_spec.component';
import { CareerComponent } from './career/career.component';


const routes: Routes = [

  // sample later change 
    // { 
    //     path: "landing", 
    //     component: LandingComponent
    // },
    { 
        path: "", 
        component: AboutUsComponent
    },
    { 
        path: "header", 
        component: HeaderComponent
    },
    { 
        path: "contactus", 
        component: ContactComponent
    },
    { 
        path: "about-us",
        component: AboutUsComponent
    },
    { 
          path: "esg_compliance",
          component: EsgComplianceComponent
      },
      { 
          path: "news",
          component: NewsComponent
      },
  
    { 
        path: "our-team",
        component: OurTeamComponent
    },
    
    { 
        path: "timeline",
        component: TimelineComponent
    },
    { 
        path: "product", 
        component: ProductComponent
    },
    { 
        path: "product_spec", 
        component: ProductSpecComponent
    },
    { 
        path: "career", 
        component: CareerComponent
    },
    
    
    // { path: "", redirectTo: "/landing", pathMatch: "full" }
  
    // { path: "**", redirectTo: "/landing" }
    // { path: "**", redirectTo: "/landing", pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
                                            scrollPositionRestoration: 'enabled', 
                                            anchorScrolling: 'enabled',
                                            onSameUrlNavigation: 'reload' 
                                        })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
