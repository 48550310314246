<app-header></app-header>


<!--Laptop-->

<div class="section desktop-bg-img hidden-sm hidden-xs">
  <h3 style="margin-top: 7%;">Our Timeline</h3>
</div>

<!-- 
<div class="section hidden-sm hidden-xs" style="background-color: darkblue;">
  <div class="col-md-12 col-xl-12">
    <h3 style="color:white;margin-top: 5%;">Manufacturing Plant Progress</h3>
  </div>
-->
  <!-- Control buttons -->
<!--  
  <div class="filterBtn">
    <button class="btn" disabled>2020 | </button>
    <button id="2020_10" class="btn active" (click)="filterSelection($event)">October</button>
    <button id="2020_11" class="btn" (click)="filterSelection($event)">November</button>
    <button id="2020_12" class="btn" (click)="filterSelection($event)">December</button>
    <br>
    <button class="btn" disabled>2021 | </button>
    <button id="2021_01" class="btn" (click)="filterSelection($event)">January</button>
    <button id="2021_02" class="btn" (click)="filterSelection($event)">February</button>
    <button id="2021_03" class="btn" (click)="filterSelection($event)">March</button>
    <button id="2021_05" class="btn" (click)="filterSelection($event)">May</button>
    <button id="2021_06" class="btn" (click)="filterSelection($event)">June</button>
    <button id="2021_07" class="btn" (click)="filterSelection($event)">July</button>
    <button id="2021_10" class="btn" (click)="filterSelection($event)">October</button>
  </div>

  <div id="ctrl2" class="carousel slide row" data-ride="carousel" data-interval="false">
    <a class="carousel-control-prev" href="#ctrl2" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <div class="carousel-inner" style="margin-left: 25%;">
      <ng-container *ngFor="let photo of slide; first as isFirst">
        <div class="item" [ngClass]="{'active': isFirst}">
          <div *ngIf="photo.fileType=='image'">
            <img [src]="photo.image" class="img-responsive desphoto">
          </div>
          <div *ngIf="photo.fileType=='video'">
            <video width="700" controls style="width: 700px;">
              <source [src]="photo.image" type="video/mp4">
            </video>
          </div>
        </div>
      </ng-container>
    </div>
    <a class="carousel-control-next" href="#ctrl2" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>
-->

<!--MOBILE PHONES & TABLET-->

<div class="section1-mobile hidden-md hidden-lg">
   <div class="container">
    <div class="col-sm-12 col-xs-12" style="margin-top:13%;margin-bottom:5%;">
      <h3> Our Timeline</h3> 
      <img class="center-block img-responsive hidden-lg hidden-md" 
      src="./assets/image/Strip(MobileResponsive)/M_PAGE_2_TIMELINE.png">  
    </div>
  
  </div>
</div>

<!--
<div class="section2-mobile hidden-md hidden-lg" style="background-color: darkblue;">
  <div class="col-xs-12">
    <h3 style="color:white;margin-bottom: 7%;">Manufacturing Plant Progress</h3>
  </div>
-->
 <!-- Control buttons -->
<!-- 
 <div class="filterBtn-mobile">
  <label class="btn-mobile" style="margin-bottom:0;padding-bottom: 0;"><span style="border-bottom: 2px solid white;font-weight: bold;">2020&nbsp;&nbsp;&nbsp;</span></label><br>
  <button id="2020_10" class="btn-mobile active" (click)="filterSelection($event)">Oct</button>
  <button id="2020_11" class="btn-mobile" (click)="filterSelection($event)">Nov</button>
  <button id="2020_12" class="btn-mobile" (click)="filterSelection($event)">Dec</button>
  <br>
  <label class="btn-mobile" style="margin-bottom:0;padding-bottom: 0;"><span style="border-bottom: 2px solid white;font-weight: bold;">2021&nbsp;&nbsp;&nbsp;</span></label><br>
  <button id="2021_01" class="btn-mobile" (click)="filterSelection($event)">Jan</button>
  <button id="2021_02" class="btn-mobile" (click)="filterSelection($event)">Feb</button>
  <button id="2021_03" class="btn-mobile" (click)="filterSelection($event)">Mar</button>
  <button id="2021_05" class="btn-mobile" (click)="filterSelection($event)">May</button>
  <button id="2021_06" class="btn-mobile" (click)="filterSelection($event)">Jun</button>
  <button id="2021_07" class="btn-mobile" (click)="filterSelection($event)">July</button>
  <button id="2021_10" class="btn-mobile" (click)="filterSelection($event)">Oct</button>
</div>
<div class="col-12">
  <div id="ctrl1" class="carousel slide row" data-ride="carousel" data-interval="false">
-->
    <!-- <a class="carousel-control-prev" href="#ctrl1" role="button" data-slide="prev" style="width:10%;">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a> -->
<!--
    <div class="carousel-inner" style="margin-left: 15%; max-width: 70%; margin-bottom: 10%;">
      <ng-container *ngFor="let photo of slide; first as isFirst">
        <div class="item" [ngClass]="{'active': isFirst}">
          <div *ngIf="photo.fileType=='image'">
            <img [src]="photo.image" class="img-responsive" style=" width: 400px;height: 300px;">
          </div>
          <div *ngIf="photo.fileType=='video'">
            <video controls >
              <source [src]="photo.image" type="video/mp4">
            </video>
          </div>
        </div>
      </ng-container>
    </div>
    <a class="carousel-control-prev" href="#ctrl1" role="button" data-slide="prev" style="width:12%;">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#ctrl1" role="button" data-slide="next"  style="width:13%;">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>
</div>
-->
<app-footer></app-footer>
