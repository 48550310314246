<app-header></app-header>

<div class="section">
  <div class="section1-wrapper">
    <img class="section1-image hidden-sm hidden-xs" src="./assets/image/Desktop/PAGE_6_BACKGROUND_01.jpg" />
    <img class="section1-image hidden-lg hidden-md" src="./assets/image/Strip(MobileResponsive)/M_PAGE_6_BACKGROUND_01.jpg" />
    <div class="row h-50">
      <div class="section1-contents col-lg-5 col-sm-5 offset-sm-7 col-7 offset-5 h-100 d-table">
        <div class="d-table-cell align-middle">
          <h2>ESG Compliance</h2>
          <h3>Environmental</h3>
          <p>Well maintenance of waste water treatment plant.<br>
            SHE policy and waste/pollution monitoring action.
          </p>
        </div> 
      </div>
    </div>
  </div>

  <div class="section2-wrapper">
    <img class="section2-image hidden-sm hidden-xs" src="./assets/image/Desktop/PAGE_6_BACKGROUND_02.jpg" />
    <img class="section2-image hidden-lg hidden-md" src="./assets/image/Strip(MobileResponsive)/M_PAGE_6_BACKGROUND_02.jpg" />
    <div class="row h-50">
      <div class="section2-contents offset-lg-2 col-sm-7 offset-sm-1 col-9 offset-1 h-100 d-table">
        <div class="d-table-cell align-middle">
          <h3>Social</h3>
          <p>Human rights - Employee handbook, employment policy, child labor.<br>
            Health and safety - Safety training, First aid training.<br>
            Community involvement - CSR activity.   
          </p>
        </div> 
      </div>
    </div>
  </div>

  <div class="section1-wrapper">
    <img class="section3-image hidden-sm hidden-xs" src="./assets/image/Desktop/PAGE_6_BACKGROUND_03.jpg" />
    <img class="section3-image hidden-lg hidden-md" src="./assets/image/Strip(MobileResponsive)/M_PAGE_6_BACKGROUND_03.jpg" />
    <div class="section3-contents col-xs-1 h-100 d-table" align="center">
      <div class="d-table-cell align-middle">
        <h3>Governance</h3>
        <p>
          Business ethics and compliance. <br> 
          Implementation of vendor integrity letter, bribery policy, supply chain vendor management procedures.
        </p>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

