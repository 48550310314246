import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

    isOpt:string = 'default';
    enquiryName: any;
    enquiryEmail: any;
    enquiryPhone: any;
    enquiryMessage: any;
    feedbackName: any;
    feedbackEmail: any;
    feedbackOrganization: any;
    feedbackPhone: any;
    feedbackInvoice: any;
    feedbackLot: any;
    feedbackProduct: any;
    feedbackDetails: any;
    fileToUpload: any;
    cardImageBase64: string = '';

  constructor(
    private apiService: ApiService,
    private titleService: Title,
    private metaService: Meta
  ) { }

  changeListener($event) : void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    var file:File = inputValue.files[0];
    var myReader:FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.fileToUpload = myReader.result;
    }
    myReader.readAsDataURL(file);
  }

  ngOnInit(): void {
    this.titleService.setTitle("Contact Us | Hong Seng Gloves")

    // console.log(document.location.href);
    if(document.location.href.includes('enquire')){
      this.selectedOpt('enquiry');
      var category = document.getElementById("category") as HTMLSelectElement;
      category.options.selectedIndex = 1;
    }
  
  }

  selectedOpt(value:string){

    switch (value) {
        case 'default':
            this.isOpt = 'default'
            break;
        case 'enquiry':
            this.isOpt = 'enquiry'
            break;
        case 'feedback':
            this.isOpt = 'feedback'
            break;
        default:
            break;
    }
  }


  sendMail() {
    switch (this.isOpt) {
      case 'enquiry':
          let data1 = {
            "mailtype": "E", //mailtype: E - Enquiry
            "enquiryName": this.enquiryName,
            "enquiryEmail": this.enquiryEmail,
            "enquiryPhone": this.enquiryPhone,
            "enquiryMessage": this.enquiryMessage,
          }
         // console.log(data1);
          this.apiService.postSendEmail(data1).subscribe((res: any) => {
            // console.log('response: ', res)

          }, error => {
            // console.log('response: ', error)
          })
        break;

      case 'feedback':
        const imgBase64Path =  this.fileToUpload
        this.cardImageBase64 = imgBase64Path;
       
          let data2 = {
            "mailtype": "C", //mailtype: C - Customer feedback
            "feedbackName": this.feedbackName,
            "feedbackEmail": this.feedbackEmail,
            "feedbackOrganization": this.feedbackOrganization,
            "feedbackPhone": this.feedbackPhone,
            "feedbackInvoice": this.feedbackInvoice,
            "feedbackLot": this.feedbackLot,
            "feedbackProduct": this.feedbackProduct,
            "feedbackDetails": this.feedbackDetails,
            "fileToUpload": this.cardImageBase64,
          }

          this.apiService.postSendEmail(data2).subscribe((res: any) => {
            // console.log('response: ', res)

          }, error => {
            // console.log('response: ', error)
          })
        break;

      default:
        break;
    }


    Swal.fire(
      'Inquiry Sent!',
      'We will contact you later, Thanks!',
      'success'
  )

  }
}


