import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

declare var require: any
@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})


export class TimelineComponent implements OnInit {

  isAnimate: boolean = false;
  isAnimateTwo: boolean = false;
  isOpen = false;
  slide:any = [];
 


  constructor(
    private titleService: Title,
    private metaService: Meta
    
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Timeline | Hong Seng Glove")

    const metaDesc = this.metaService.getTag('name=description');

    if (!metaDesc) {
      this.metaService.addTags([
        { name: "description", content: "The healthcare and glove industry remains poised for massive growth, and with all of our combined efforts, we look forward to generating value for each other as well as seek new opportunities for expansion. We aim to create the nation’s first fully integrated glove manufacturing facility and a sustainable end-to-end production supply chain in the Malaysian glove industry." }
      ]);
    } else {
      this.metaService.updateTag(
        { name: "description", content: "The healthcare and glove industry remains poised for massive growth, and with all of our combined efforts, we look forward to generating value for each other as well as seek new opportunities for expansion. We aim to create the nation’s first fully integrated glove manufacturing facility and a sustainable end-to-end production supply chain in the Malaysian glove industry." },
        "name=description"
      )
    }
    // const el = document.getElementById('2020_10');
    // el.click();
    
  }


  // filterSelection(event): void {
  //   let res = event.target.id;
  //   console.log('res: ' + res)
  //   this.slide = [];
  //   let images:any
  //   if(res=='2020_10'){
  //      images =[ 
  //       {id : "1",  image : "./assets/image/Slide/2020_10_01.jpg", fileType:"image"},
  //       {id : "2",  image : "./assets/image/Slide/2020_10_02.jpg", fileType:"image"},
  //       {id : "3",  image : "./assets/image/Slide/2020_10_03.jpg", fileType:"image"},
  //       {id : "4",  image : "./assets/image/Slide/2020_10_04.jpg", fileType:"image"},
  //       {id : "5",  image : "./assets/image/Slide/2020_10_05.jpg", fileType:"image"},
  //       {id : "6",  image : "./assets/image/Slide/2020_10_06.jpg", fileType:"image"},
  //       {id : "7",  image : "./assets/image/Slide/2020_10_07.jpg", fileType:"image"},
  //       {id : "8",  image : "./assets/image/Slide/2020_10_08.jpg", fileType:"image"},
  //       {id : "9",  image : "./assets/image/Slide/2020_10_09.jpg", fileType:"image"},
  //       {id : "10", image : "./assets/image/Slide/2020_10_10.mp4", fileType:"video"},
  //       {id : "11", image : "./assets/image/Slide/2020_10_11.mp4", fileType:"video"}
  //     ]
  //     //this.slide = Object.values(images);
  //     //console.log(Object.values(images));
  //   }
  //   else if(res=='2020_11'){
  //     images =[ 
  //       {id : "1",  image : "./assets/image/Slide/2020_11_01.jpg", fileType:"image"},
  //       {id : "2",  image : "./assets/image/Slide/2020_11_02.jpg", fileType:"image"}
  //     ]     
  //   }
  //   else if(res=='2020_12'){
  //     images =[ 
  //       {id : "1",  image : "./assets/image/Slide/2020_12_01.png", fileType:"image"},
  //       {id : "2",  image : "./assets/image/Slide/2020_12_02.png", fileType:"image"},
  //       {id : "3",  image : "./assets/image/Slide/2020_12_03.png", fileType:"image"},
  //       {id : "4",  image : "./assets/image/Slide/2020_12_04.png", fileType:"image"},
  //       {id : "5",  image : "./assets/image/Slide/2020_12_05.jpg", fileType:"image"}
  //     ]     
  //   }
  //   else if(res=='2021_01'){
  //     images =[ 
  //       {id : "1",  image : "./assets/image/Slide/2021_01_01.jpeg", fileType:"image"},
  //       {id : "2",  image : "./assets/image/Slide/2021_01_02.jpeg", fileType:"image"},
  //       {id : "3",  image : "./assets/image/Slide/2021_01_03.jpeg", fileType:"image"},
  //       {id : "4",  image : "./assets/image/Slide/2021_01_04.jpeg", fileType:"image"}
  //     ]     
  //   }
  //   else if(res=='2021_02'){
  //     images =[ 
  //       {id : "1",  image : "./assets/image/Slide/2021_02_01.jpeg", fileType:"image"},
  //       {id : "2",  image : "./assets/image/Slide/2021_02_02.jpeg", fileType:"image"},
  //       {id : "3",  image : "./assets/image/Slide/2021_02_03.jpeg", fileType:"image"},
  //       {id : "4",  image : "./assets/image/Slide/2021_02_04.jpeg", fileType:"image"},
  //       {id : "5",  image : "./assets/image/Slide/2021_02_05.jpeg", fileType:"image"},
  //       {id : "6",  image : "./assets/image/Slide/2021_02_06.jpeg", fileType:"image"},
  //       {id : "7",  image : "./assets/image/Slide/2021_02_07.jpeg", fileType:"image"},
  //       {id : "8",  image : "./assets/image/Slide/2021_02_08.jpeg", fileType:"image"},
  //       {id : "9",  image : "./assets/image/Slide/2021_02_09.jpeg", fileType:"image"}
  //     ]     
  //   }
  //   else if(res=='2021_03'){
  //     images =[ 
  //       {id : "1",  image : "./assets/image/Slide/2021_03_01.jpeg", fileType:"image"},
  //       {id : "2",  image : "./assets/image/Slide/2021_03_02.jpeg", fileType:"image"},
  //       {id : "3",  image : "./assets/image/Slide/2021_03_03.jpeg", fileType:"image"},
  //       {id : "4",  image : "./assets/image/Slide/2021_03_04.jpeg", fileType:"image"}
  //     ]     
  //   }
  //   else if(res=='2021_05'){
  //     images =[ 
  //       {id : "1",  image : "./assets/image/Slide/2021_05_01.jpeg", fileType:"image"},
  //       {id : "2",  image : "./assets/image/Slide/2021_05_02.jpeg", fileType:"image"}
  //     ]     
  //   }
  //   else if(res=='2021_06'){
  //     images =[ 
  //       {id : "1",  image : "./assets/image/Slide/2021_06_01.jpeg", fileType:"image"},
  //       {id : "2",  image : "./assets/image/Slide/2021_06_02.jpeg", fileType:"image"},
  //       {id : "3",  image : "./assets/image/Slide/2021_06_03.jpeg", fileType:"image"},
  //       {id : "4",  image : "./assets/image/Slide/2021_06_04.jpeg", fileType:"image"},
  //       {id : "5",  image : "./assets/image/Slide/2021_06_05.jpeg", fileType:"image"}
  //     ]     
  //   }
  //   else if(res=='2021_07'){
  //     images =[ 
  //       {id : "1" ,  image : "./assets/image/Slide/2021_07_01.jpeg", fileType:"image"},
  //       {id : "2" ,  image : "./assets/image/Slide/2021_07_02.jpeg", fileType:"image"},
  //       {id : "3" ,  image : "./assets/image/Slide/2021_07_03.jpeg", fileType:"image"},
  //       {id : "4" ,  image : "./assets/image/Slide/2021_07_04.jpeg", fileType:"image"},
  //       {id : "5" ,  image : "./assets/image/Slide/2021_07_05.jpeg", fileType:"image"},
  //       {id : "6" ,  image : "./assets/image/Slide/2021_07_06.jpeg", fileType:"image"},
  //       {id : "7" ,  image : "./assets/image/Slide/2021_07_07.jpeg", fileType:"image"},
  //       {id : "8" ,  image : "./assets/image/Slide/2021_07_08.jpeg", fileType:"image"},
  //       {id : "9" ,  image : "./assets/image/Slide/2021_07_09.jpeg", fileType:"image"},
  //       {id : "10",  image : "./assets/image/Slide/2021_07_10.jpeg", fileType:"image"},
  //       {id : "11",  image : "./assets/image/Slide/2021_07_11.jpeg", fileType:"image"},
  //       {id : "12",  image : "./assets/image/Slide/2021_07_12.jpeg", fileType:"image"}
  //     ]     
  //   }
  //   else if(res=='2021_10'){
  //     images =[ 
  //       {id : "1",  image : "./assets/image/Slide/2021_10_01.mp4", fileType:"video"}
  //     ]     
  //   }
  //   this.slide = Object.values(images);

  // }



}



