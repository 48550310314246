import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser'; 

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.css']
})
export class OurTeamComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaService: Meta
  ) { }

  ngOnInit(): void {
        this.titleService.setTitle("Our Team | Hong Seng Glove")

        const metaDesc = this.metaService.getTag('name=description'); 

        if(!metaDesc){
            this.metaService.addTags([
                {name: "description", content: "The healthcare and glove industry remains poised for massive growth, and with all of our combined efforts, we look forward to generating value for each other as well as seek new opportunities for expansion. We aim to create the nation’s first fully integrated glove manufacturing facility and a sustainable end-to-end production supply chain in the Malaysian glove industry."}
            ]);
        }else{
            this.metaService.updateTag(  
                {name: "description", content: "The healthcare and glove industry remains poised for massive growth, and with all of our combined efforts, we look forward to generating value for each other as well as seek new opportunities for expansion. We aim to create the nation’s first fully integrated glove manufacturing facility and a sustainable end-to-end production supply chain in the Malaysian glove industry." },  
                "name=description"  
            )  
        }
  }

}




