import { style } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-product_spec',
  templateUrl: './product_spec.component.html',
  styleUrls: ['./product_spec.component.css']
})
export class ProductSpecComponent implements OnInit {
  constructor(
    private titleService: Title
  ) { }
  ngOnInit(): void {
    this.titleService.setTitle("Product Specification | Hong Seng Gloves")

    //FOR BUTTONS & TABLES
    const btn1 = document.getElementById('btn1') as HTMLButtonElement | null;
    const btn2 = document.getElementById('btn2') as HTMLButtonElement | null;
    const btn3 = document.getElementById('btn3') as HTMLButtonElement | null;

    const buttons = [btn1, btn2, btn3];

    buttons.forEach(button => {
      button.addEventListener('click', e => {
        if (!(e.target instanceof HTMLButtonElement)) {
          return;
        }

        showTable(e.target.dataset.table);

        e.preventDefault();
      });
    });

    function showTable(idTable) {
      const tables = document.querySelectorAll('table');
      tables.forEach(table => {
        table.id === idTable ? table.style.display = 'block' : table.style.display = 'none';
        table.id == "tb4" ? table.style.display = 'block' : table.style.display = 'visible'; //KEEP THE TABLE IN SECTION 2 VISIBLE
      });
    }

    //FOR LI, SPAN & PRODUCT IMAGES
    const list = document.querySelectorAll('li');
    list.forEach(li => {
      li.addEventListener('click', e => {
        if (!(e.target instanceof HTMLLIElement)) {
          return;
        }
        showImage(e.target.dataset.image);
      });
    });

    const spans = document.querySelectorAll('span');
    spans.forEach(span => {
      span.addEventListener('click', e => {
        if (!(e.target instanceof HTMLSpanElement)) {
          return;
        }
        showImage(e.target.dataset.image);
      });
    });

    function showImage(idImage) {
      // console.log("idImage: ", idImage);
      const img1 = document.getElementById('img1') as HTMLImageElement | null;
      const img2 = document.getElementById('img2') as HTMLImageElement | null;
      const img3 = document.getElementById('img3') as HTMLImageElement | null;

      switch (idImage) {
        case 'img1':
          img1.style.display = 'block';
          img2.style.display = 'none';
          img3.style.display = 'none';
          document.getElementById('weight').innerHTML="3.2G to 5.0G (Available for customization)";
          document.getElementById('length').innerHTML="Min 240";
          break;
        case 'img2':
          img1.style.display = 'none';
          img2.style.display = 'block';
          img3.style.display = 'none';
          document.getElementById('weight').innerHTML="3.5G to 5.5G (Available for customization)";
          document.getElementById('length').innerHTML="Min 240 & 295";
          break;
        case 'img3':
          img1.style.display = 'none';
          img2.style.display = 'none';
          img3.style.display = 'block';
          document.getElementById('weight').innerHTML="3.2G to 3.5 G (Available for customization)";
          document.getElementById('length').innerHTML="Min 240";
          break;
        default:
          break;
      }
    }
  }
}


