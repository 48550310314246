<app-header></app-header>

<div class="section">
  <div class="section1">
    <!-- SMARTPHONES -->
    <div class="section1-mobile hidden-sm hidden-md hidden-lg px-5">
      <div class="section1-mobile-contents">
        <h3>Quality Policy</h3>
        <p>
          Hong Seng Gloves Sdn. Bhd. aims to be the leading supplier of Premium Quality Medical Nitrile Gloves. 
        </p>
        <p>
          Our key goal is to commit, strive and deliver the highest priority to our customers satisfaction with quality products that meet and exceed their expectations by ensuring that their requirements are met.
        </p>
        <p>
          To achieve the above goal, we are committed to comply to all customer’s requirements, statutory & regulatory requirements, and relevant standards.
        </p> 
        <p>
          Our company is always emphasizing an integrated teamwork approach towards the quality commitment and our effectiveness of Quality Management 
          System by promoting a culture of continuous improvement as well as continuously upgrading our workforce job competency through training and development of their professional skills. 
        </p>
      </div>
    </div>

    <!-- LAPTOPS & TABLETS -->
    <div class="section1-desktop hidden-xs">
      <div class="section1-feature">
        <div class="section1-desktop-contents">
          <h3 class="col-md-12">Quality Policy</h3>
          <p class="col-md-9 col-sm-6"><br>
            Hong Seng Gloves Sdn. Bhd. aims to be the leading supplier of Premium Quality Medical Nitrile Gloves. Our key goal is to commit, strive and deliver the highest priority to our customers satisfaction with quality products that meet and exceed their expectations by ensuring that their requirements are met.
          </p>
          <p class="col-md-11 col-sm-9">
            To achieve the above goal, we are committed to comply to all customer’s requirements, statutory & regulatory requirements, and relevant standards. Our company is always emphasizing an integrated teamwork approach towards the quality commitment and our effectiveness of Quality Management 
            System by promoting a culture of continuous improvement as well as continuously upgrading our workforce job competency through training and development of their professional skills. 
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- SMARTPHONES & TABLETS -->
  <div class="section2-mobile hidden-md hidden-lg" style="text-align:center;">
    <h3>Stringent Quality Control</h3>
    <h3 style="padding-top:0; font-weight:normal !important;">Quality Management System</h3>
    <img class="img1" src="./assets/image/Icons/STINGENT_01.jpg" />
    <h3>Product Compliances</h3>
    <img class="img2" src="./assets/image/Icons/PRODUCT_COMPLIANCE_(MOBILE).jpg" />
    <h3>Market Registration</h3>
    <img class="img3" src="./assets/image/Icons/MARKET_REGISTRATION.png" />
  </div>

  <!-- LAPTOPS -->
  <div class="section2-desktop hidden-xs hidden-sm" style="text-align:center;">
    <div class="section2-contents">
      <h3>Stringent Quality Control</h3>
      <h3 style="padding-top:0; font-weight:normal !important;">Quality Management System</h3>
      <img class="img1" src="./assets/image/Icons/STINGENT_01.jpg" />
      <h3>Product Compliances</h3>
      <img class="img2" src="/assets/image/Icons/PRODUCT_COMPLIANCE_01.png" />
      <h3>Market Registration</h3>
      <img class="img3" src="./assets/image/Icons/MARKET_REGISTRATION.png" />
    </div>
  </div>
</div>

<app-footer></app-footer>





