<app-header></app-header>

<div class="section">
  <div class="section1">
    <!-- SMARTPHONES -->
    <div class="section1-mobile hidden-sm hidden-md hidden-lg px-5">
      <div class="section1-mobile-contents">
        <h3>Dato' Seri Teoh Hai Hin</h3>
        <p>Managing Director</p>
        <p>
          Dato' Seri Teoh Hai Hin is currently the Managing Director of Hong Seng Gloves Sdn. Bhd. and Group 
          Managing Director for Hong Seng Consolidated Berhad and is responsible of overseeing the Group's 
          operations and has been instrumental in the growth and development of Hong Seng Group.  
        </p>
        <p>
          In addition, he also sits as Board member of Hong Seng Group of Companies.
        </p>
        <p>
          Dato’ Seri Teoh was awarded the "2010 Golden Buff Award" by the organising committee and was 
          the 7th place winner in the "Super Golden Bull" category in Malaysia. In addition, he was also 
          awarded "Kwong Wah Yit Poh Centennial Awards" in 2010 and the winner of the "Dr Sun Yat Sen 
          Enterprise Excellent Award."
        </p>
      </div>
    </div>

    <!-- LAPTOPS & TABLETS -->
    <div class="section1-desktop hidden-xs">
      <div class="section1-feature">
        <div class="section1-desktop-contents">
          <h3 class="col-md-12">Dato' Seri Teoh Hai Hin</h3>
          <p class="col-md-12" style="font-size: 20px;">Managing Director</p>
          <p class="col-md-12 col-sm-7"><br>
            Dato' Seri Teoh Hai Hin is currently the Managing Director of Hong Seng Gloves Sdn. Bhd. and Group 
            Managing Director for Hong Seng Consolidated Berhad and is responsible of overseeing the Group's 
            operations and has been instrumental in the growth and development of Hong Seng Group. 
          </p>
          <p class="col-md-12 col-sm-7">
            In addition, he also sits as Board member of Hong Seng Group of Companies.
          </p>
          <p class="col-md-12 col-sm-7">
            Dato’ Seri Teoh was awarded the "2010 Golden Buff Award" by the organising committee and was 
            the 7th place winner in the "Super Golden Bull" category in Malaysia. In addition, he was also 
            awarded "Kwong Wah Yit Poh Centennial Awards" in 2010 and the winner of the "Dr Sun Yat Sen 
            Enterprise Excellent Award."
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- SMARTPHONES, TABLETS & LAPTOPS -->
  <div class="section2">
    <div class="section2-wrapper">
      <div class="gallery">
        <div class="gallery-item">
          <h3>DIRECTOR</h3>
          <figure>
            <img src="./assets/image/Icons/Francis.jpeg">
            <figcaption>
              <h4>Francis Ho Chia Yao</h4>
              <p>Francis started his glove career in 1999 as a Factory Manager for 
                 YTY Industry Sdn Bhd and Group Factory General Manager at its 
                 subsidiary, Green Prospect Sdn Bhd. He has since overseen 
                 operation manufacturing of 28 lines with more than 2800 
                 employees.</p>
              <p>He later moved to Central Medicare Sdn Bhd as Operations 
                 Director and Head of Administration, responsible for operation 
                 of double former glove manufacturing plant with more than 
                 1000 workers.</p>
              <p>In 2015, Francis venture into startup of the glove industry. He 
                 involved in various project management discipline which 
                 include construction, design and build of glove manufacturing 
                 plant. His dedication has led to the successful development of a 
                 59 acres land into an integrated NBR glove manufacturing plant 
                 in Terengganu.</p>
              <p>With his vast experience in the field, Francis currently holds 
                 position as Director of Hong Seng Gloves Sdn. Bhd. focusing not 
                 only on startup, but also on the operational management.</p>
            </figcaption>
          </figure>
        </div>
        <div class="gallery-item">
          <h3>GENERAL <br> MANAGER</h3>
          <figure>
            <img src="./assets/image/Icons/Zahari.jpeg">
            <figcaption>
              <h4>Zahari Bin Darus</h4>
              <p>Zahari had a total of 32 years of experience in glove industries. Zahari started his 
                 gloves career in 1988. He has worked with few renowned latex glove companies as 
                 production supervisor where he horned his supervising and machine 
                 troubleshooting skills.</p>
              <p>In 1999, Zahari explored into Quality Assurance Executive and was promoted to 
                 Quality Assurance and Regulatory Affairs Manager within a year. In this role, he 
                 managed the development and maintenance of quality system and quality 
                 assurance in the company. He was appointed as the Management Representative 
                 to handle the third-party audit and customer audit due to his vast experience. 
                 Since then, Zahari had been holding Quality Assurance Manager for Asia Pacific 
                 Latex Sdn. Bhd., WRP Asia Pacific Sdn. Bhd. and Green Prospect Sdn. Bhd.
                </p>
              <p>His credibility and dedication in work had built confidence in the glove industries as 
                 he held position as Operation Manger manager at Advance Medical Products in 
                 2010. He is later promoted to General Manager for YTY Industry Manjung Sdn. Bhd. 
                 and Senior General Manger in Rubberex Alliance Sdn. Bhd.</p>
              <p>He venture into factory start up project at Rimba Glove Sdn Bhd in 2020 as General 
                 Manager, managing the project and facility build up in collaboration with 
                 established and certification of ISO 13485 Quality Management System. In 2022, he 
                 joined Hong Seng Gloves Sdn. Bhd. as General Manager and continue to 
                 contributing knowledge support and lead the team towards company goals.</p>
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

