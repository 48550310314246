import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from "rxjs";
import { PlatformLocation } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

    hongsengglovesURL: any;
    currBaseURL: any;
    trackerURL:any;
    cmsURL: any;
    token:any;

    tokenCMS:any;

  constructor(
      private http: HttpClient,
      private platformLocation: PlatformLocation
  ) {
      this.checkBaseUrl();
  }

  checkBaseUrl() {

      this.currBaseURL = (this.platformLocation as any).location.origin;

      let prodURL = this.currBaseURL.match(/production.ai/g);
      let localURL = this.currBaseURL.match(/localhost/g);

      if (prodURL != null) {
        //   this.hongsengglovesURL = "http://staging.hongsenggloves.com/my";
        this.hongsengglovesURL = "http://api.hongsenggloves.com/public/";

      } else {
         this.hongsengglovesURL = "http://localhost:8080/hongsenggloves-web-api";
        // this.hongsengglovesURL = "http://staging.hongsenggloves.com/my";

      }
  }


    getAnnouncer():Observable<any> {

        const httpOptions = {
            headers: new HttpHeaders(
            {
                'Authorization': `Bearer ${this.tokenCMS}`,
                'Content-Type': 'application/json'
            })
        }

        const url = this.cmsURL + "/api/retrieve_popup";
        return this.http.get(url, httpOptions);
    }

    getFaqList():Observable<any> {

        const httpOptions = {
            headers: new HttpHeaders(
            {
                'Authorization': `Bearer ${this.tokenCMS}`,
                'Content-Type': 'application/json'
            })
        }

        const url = this.cmsURL + "/api/retrieve_faq";
        return this.http.get(url, httpOptions);
    }

    getBannerList():Observable<any> {

        const httpOptions = {
            headers: new HttpHeaders(
            {
                'Authorization': `Bearer ${this.tokenCMS}`,
                'Content-Type': 'application/json'
            })
        }

        const url = this.cmsURL + "/api/retrieve_banner";
        return this.http.get(url, httpOptions);
    }

    getSliderList():Observable<any> {

        const httpOptions = {
            headers: new HttpHeaders(
            {
                'Authorization': `Bearer ${this.tokenCMS}`,
                'Content-Type': 'application/json'
            })
        }

        const url = this.cmsURL + "/api/retrieve_slider";
        return this.http.get(url, httpOptions);
    }

    getQuotation(zipfrom, zipto, weight, parceltype){

        const url =
        "/quote/quote.php"+
        "?zipfrom=" + zipfrom +
        "&zipto=" + zipto +
        "&weight=" + weight +
        "&parceltype=" + parceltype;


        return this.http.get(this.hongsengglovesURL + url);
    }

    postSendEmail(data){

        const httpOptions = {
            headers: new HttpHeaders(
            {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Origin, Methods, Content-Type',
                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
            })
        }

        //const url =this.hongsengglovesURL + "/enquiry.php";
      //const url ="http://staging.hongsenggloves.com/my/enquiry.php";
       //const url ="http://127.0.0.1:8000/api/send-email";
    const url ="https://api.hongsenggloves.com/public/api/send-email";
            console.log(data);
        //return this.http.post(url, JSON.stringify(data), httpOptions);
        return this.http.post<any>(url, JSON.stringify(data), httpOptions);
    }


    getPitstopLocator(postcode){

        const url =
        "/quote/locatebranch.php"+
        "?search=" + postcode;
        console.log(this.hongsengglovesURL + url)
        return this.http.get(this.hongsengglovesURL + url);
    }

    postTracker(data):Observable<any> {

        const httpOptions = {
            headers: new HttpHeaders(
            {
                'Authorization': `Bearer ${this.token}`,
                'Content-Type': 'application/json'
            })
        }

        const url = this.trackerURL + "/viewandtrack";
        return this.http.post(url, data, httpOptions);
    }


}

