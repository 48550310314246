import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser'; 

@Component({
  selector: 'app-esg_compliance',
  templateUrl: './esg_compliance.component.html',
  styleUrls: ['./esg_compliance.component.css']
})
export class EsgComplianceComponent implements OnInit {

  constructor(
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("ESG Compliance | Hong Seng Gloves")
  }

}
